import React, { useEffect, useState } from "react";
import { rep_getGrant, rep_authHeaders, config } from "data/repository";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import noImage from "assets/img/no_image.jpg";
import { useParams } from "react-router-dom";
import moment from "moment";

import Arrow from "@material-ui/icons/ArrowRight";
import Bulb from "@material-ui/icons/ThumbUp";
import ArrowLeft from "@material-ui/icons/ArrowBackIos";

import { Link } from "@material-ui/core";

import ReactHtmlParser from "react-html-parser";

import axios from "axios";

const styles = {
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    ["@media (max-width:780px)"]: {
      paddingTop: "20px",
    },
  },
  sectionHeading: {
    fontSize: "28px",
    lineHeight: "1em",
    marginBottom: "20px",
    marginTop: "30px",
    paddingBottom: "5px",
    paddingLeft: "30px",
    position: "relative",
    color: "#0d2b45",
    "&::before": {
      content: "''",
      display: "block",
      width: "5px",
      height: "25px",
      backgroundColor: "#ba0c2f",
      position: "absolute",
      left: 0,
      top: 0,
    },
    "&::after": {
      content: "''",
      display: "block",
      width: "5px",
      height: "25px",
      backgroundColor: "#be9826",
      position: "absolute",
      left: "7px",
      top: "5px",
    },
  },
  icons: {
    // width: "80px",
    // height: "80px",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: "15px",
    fontSize: "30px",
    color: "#ba0c2f",
  },
  rsIconInfo: {
    marginBottom: "-20px",
    color: "#222",
    fontSize: "11px",
  },
  infoIcon: {
    display: "table-cell",
    position: "relative",
  },
  infoText: {
    display: "table-cell",
    paddingLeft: "20px",
    position: "relative",
    verticalAlign: "middle",
  },
  btIcons: {
    width: "40px !important",
    height: "40px !important",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: "0",
    color: "#fff", //"#ba0c2f",
    transition: "all 0.3s ease",
  },
  btIconInfo: {
    width: "100%",
    color: "#fff",
    fontSize: "18px",
    backgroundColor: "#be9826",
    opacity: "90%",
    transition: "all 0.3s ease",
    "&:hover": {
      opacity: "100%",
      backgroundColor: "#ba0c2f",
    },
  },
  btInfoIcon: {
    display: "table-cell",
    position: "relative",
  },
  btInfoText: {
    display: "table-cell",
    fontWeight: "bold !important",
    paddingLeft: "20px",
    position: "relative",
    verticalAlign: "middle",
  },
  grantDescriptionLink: {
    overflow: "hidden",
    "& a": {
      color: "#ba0c2f",
    },
    "& a:hover,& a:focus": {
      color: "#be9826",
    },
  },
  grantLinks: {
    "&, & a": {
      color: "#ba0c2f",
    },
    "& a:hover,& a:focus": {
      color: "#be9826",
    },
  },
  // title: {
  //   fontSize: "16px",
  // },
  bold: {
    fontWeight: "bold",
  },
  padding: {
    padding: "5px 0",
  },
  iconsBread: {
    // width: "80px",
    // height: "80px",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: "7px",
    fontSize: "25px",
    color: "#ba0c2f",
  },
  rsIconInfoBread: {
    color: "#222",
    fontSize: "11px",
  },
  infoIconBread: {
    display: "table-cell",
    position: "relative",
  },
  infoTextBread: {
    display: "table-cell",
    paddingLeft: "0px",
    position: "relative",
    verticalAlign: "middle",
    "&, & a": {
      color: "#ba0c2f",
    },
    "& a:hover,& a:focus": {
      color: "#be9826",
      textDecoration: "none",
    },
  },
  container: {
    padding: "0 15%",
    ["@media (max-width:599px)"]: {
      padding: "0",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Grant() {
  let { id } = useParams();
  const classes = useStyles();
  const [grant, setGrant] = useState({
    id: id,
    id_category: "",
    name: "",
    description: "",
    amount: "",
    begin_date: new Date(),
    end_date: new Date(),
    call: "",
    request: "",
    image: noImage,
    on_cover: false,
    video: "",
    cover_image: noImage,
    finished: false,
  });
  const [grants, setGrants] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id !== undefined) {
      getGrantData();
    } else {
      setLoading(false);
    }
  }, []);

  const getGrantData = async () => {
    const grantData = await rep_getGrant(id);

    setGrant(grantData);
    getGrantsByCategoryData(grantData.id_category);
  };

  const getGrantsByCategoryData = (categoryId) => {
    axios
      .get(config.API_URL + "admin/ccg_grant/all/" + categoryId, {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setGrants(response.data), setLoading(false);
      });
  };

  if (!loading) {
    return (
      <div>
        <div className={classes.rsIconInfoBread}>
          <div className={classes.infoIconBread}>
            <ArrowLeft className={classes.iconsBread} />
          </div>
          <div className={classes.infoTextBread}>
            <Link
              href={"/admin/categories/"}
              // target="_blank"
              style={{
                fontSize: "20px",
              }}
            >
              Categorías
            </Link>
          </div>
          <div className={classes.infoIconBread}>
            <ArrowLeft
              className={classes.iconsBread}
              style={{
                marginLeft: "15px",
              }}
            />
          </div>
          <div className={classes.infoTextBread}>
            <Link
              href={"/admin/grants/" + grant.id_category}
              // target="_blank"
              style={{
                fontSize: "20px",
              }}
            >
              Ayudas
            </Link>
          </div>
        </div>
        <GridContainer
          style={{
            padding: "0 12%",
          }}
        >
          <GridItem xs={12} sm={8}>
            {grant.video ? (
              <div style={{ display: "flex" }}>
                <iframe
                  width="560"
                  height="315"
                  src={"https://www.youtube.com/embed/" + grant.video}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            ) : (
              ""
            )}
            <h3 className={classes.sectionHeading}>{grant.name}</h3>
            <div className={classes.grantDescriptionLink}>
              {ReactHtmlParser(grant.description)}
            </div>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoIcon}>
                <Arrow className={classes.icons} />
              </div>
              <div className={classes.infoText}>
                <h4 className={classes.title}>
                  <span className={classes.bold}>Incentivo: </span>
                  {grant.amount}
                </h4>
              </div>
            </div>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoIcon}>
                <Arrow className={classes.icons} />
              </div>
              <div className={classes.infoText}>
                <h4 className={classes.title}>
                  <span className={classes.bold}>Fecha de inicio: </span>
                  {moment(grant.begin_date).format("DD/MM/YYYY")}
                </h4>
              </div>
            </div>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoIcon}>
                <Arrow className={classes.icons} />
              </div>
              <div className={classes.infoText}>
                <h4 className={classes.title}>
                  <span className={classes.bold}>Fecha de fin: </span>
                  {moment(grant.end_date).format("DD/MM/YYYY")}
                </h4>
              </div>
            </div>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoIcon}>
                <Arrow className={classes.icons} />
              </div>
              <div className={classes.infoText}>
                <h4 className={classes.title}>
                  <span className={classes.bold}>Convocatoria: </span>
                  <Link
                    href={grant.call}
                    target="_blank"
                    className={classes.grantLinks}
                  >
                    Ver convocatoria
                  </Link>
                </h4>
              </div>
            </div>
          </GridItem>
          {/* ---------------------------------------------------------------------------------------------- */}
          <GridItem xs={12} md={4}>
            <img
              className={classes.imgFluid}
              src={
                grant.image
                  ? grant.image.includes("blob") || grant.image.includes("data")
                    ? grant.image
                    : grant.image +
                      "?" +
                      moment().format("grant_image_DDMMYYYYhhmmss")
                  : noImage
              }
              alt="Selecciona una imagen"
            />
            <Button
              href={grant.req}
              target="_blank"
              className={classes.btIconInfo}
            >
              <div className={classes.btInfoIcon}>
                <Bulb className={classes.btIcons} />
              </div>
              <div className={classes.btInfoText}>
                <p className={classes.title}>Solicitar Ayuda</p>
              </div>
            </Button>
            <div>
              <h4>Otras ayudas asociadas</h4>
            </div>
            {grants.map((value) => {
              if (value.id != grant.id) {
                return (
                  <div className={(classes.rsIconInfo, classes.padding)}>
                    <div className={classes.infoIcon}>
                      <Arrow className={classes.icons} />
                    </div>
                    <div className={classes.infoText}>
                      <Link
                        key={value.id}
                        className={classes.grantLinks}
                        href={"/admin/grant/" + value.id}
                        // target="_blank"
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {value.name}
                      </Link>
                    </div>
                  </div>
                );
              }
            })}
          </GridItem>
          {/* ---------------------------------------------------------------------------------------------- */}
          {/* <GridItem sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <img
                      src={
                        grant.image
                          ? grant.image.includes("blob") ||
                            grant.image.includes("data")
                            ? grant.image
                            : grant.image +
                              "?" +
                              moment().format("grant_image_DDMMYYYYhhmmss")
                          : noImage
                      }
                      alt="Selecciona una imagen"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div>
                      <h2>{grant.name}</h2>
                    </div>
                    <div>{grant.description}</div>
                    <div>
                      Cantidad: <b>{grant.amount} €</b>
                    </div>
                    <div>
                      Fecha de inicio: <b>{grant.begin_date}</b>
                    </div>
                    <div>
                      Fecha de fin: <b>{grant.end_date}</b>
                    </div>
                    <div>
                      URL de la convocatoria:
                      <Link href={grant.call}>{grant.call}</Link>
                    </div>
                    <div>
                      <Button href={grant.request} color="primary">
                        Pedir
                      </Button>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem> */}
        </GridContainer>
      </div>
    );
  } else {
    return <div>Cargando...</div>;
  }
}

Grant.propTypes = {
  grantId: PropTypes.object,
};
