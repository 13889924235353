import { ROLES } from "data/repository";
import { isAllowed, isLogged } from "login";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";
//import BubbleChart from "@material-ui/icons/BubbleChart";
import Notifications from "@material-ui/icons/Notifications";
import CategoryIcon from "@material-ui/icons/AccountTree";
import GrantIcon from "@material-ui/icons/Euro";
import SearchIcon from "@material-ui/icons/Search";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/Users/UserProfile.js";
import Users from "views/Users/Users.js";
import Categories from "views/CCG/Private/Categories.js";
import Category from "views/CCG/Private/Category.js";
import Grants from "views/CCG/Private/Grants.js";
import Grant from "views/CCG/Private/Grant.js";
import Search from "views/CCG/Private/Search.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import PublicCategories from "views/CCG/Public/Categories.js";
import PublicCategory from "views/CCG/Public/Category.js";
import PublicGrants from "views/CCG/Public/Grants.js";
import PublicGrant from "views/CCG/Public/Grant.js";
import PublicSearch from "views/CCG/Public/Search.js";
import ListGrants from "views/CCG/Public/ListGrants.js";

let dashboardRoutes = [];

if (isLogged()) {
  if (isAllowed(ROLES.SUPERADMIN)) {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Panel",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
        id: "dashboard",
      },
      {
        path: "/users",
        name: "Usuarios",
        icon: Group,
        component: Users,
        layout: "/admin",
        id: "users",
      },
      {
        path: "/user",
        name: "Perfil de usuario",
        icon: Person,
        component: UserProfile,
        layout: "/admin",
        id: "user",
      },
      {
        path: "/categories",
        name: "Categorías",
        icon: CategoryIcon,
        component: Categories,
        layout: "/admin",
        id: "categories",
      },
      {
        path: "/category/:id?",
        name: "Nueva Categoría",
        icon: CategoryIcon,
        component: Category,
        layout: "/admin",
        id: "category",
        parent: "categories",
      },
      {
        path: "/grants",
        name: "Ayudas",
        icon: GrantIcon,
        component: Grants,
        layout: "/admin",
        id: "grants",
      },
      {
        path: "/grant/:id?",
        name: "Nueva Ayuda",
        icon: GrantIcon,
        component: Grant,
        layout: "/admin",
        id: "grant",
        parent: "grants",
      },
      {
        path: "/notifications",
        name: "Notificaciones",
        icon: Notifications,
        component: NotificationsPage,
        layout: "/admin",
        id: "notifications",
      },
      {
        path: "/search/:textToSearch?",
        name: "Buscar",
        icon: SearchIcon,
        component: Search,
        layout: "/admin",
        id: "search",
      },
    ];
  } else if (isAllowed(ROLES.ADMIN)) {
    dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Panel",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
        id: "dashboard",
      },
      {
        path: "/categories",
        name: "Categorías",
        icon: CategoryIcon,
        component: Categories,
        layout: "/admin",
        id: "categories",
      },
      {
        path: "/category/:id?",
        name: "Nueva Categoría",
        icon: CategoryIcon,
        component: Category,
        layout: "/admin",
        id: "category",
        parent: "categories",
      },
      {
        path: "/grants",
        name: "Ayudas",
        icon: GrantIcon,
        component: Grants,
        layout: "/admin",
        id: "grants",
      },
      {
        path: "/grant/:id?",
        name: "Nueva Ayuda",
        icon: GrantIcon,
        component: Grant,
        layout: "/admin",
        id: "grant",
        parent: "grants",
      },
      {
        path: "/notifications",
        name: "Notificaciones",
        icon: Notifications,
        component: NotificationsPage,
        layout: "/admin",
        id: "notifications",
      },
      {
        path: "/search/:textToSearch?",
        name: "Buscar",
        icon: SearchIcon,
        component: Search,
        layout: "/admin",
        id: "search",
      },
    ];
  } else if (isAllowed(ROLES.COMPANY)) {
    dashboardRoutes = [
      {
        path: "/categories",
        name: "Categorías",
        icon: CategoryIcon,
        component: PublicCategories,
        layout: "/admin",
        id: "categories",
      },
      {
        path: "/category/:id?",
        name: "Nueva Categoría",
        icon: CategoryIcon,
        component: PublicCategory,
        layout: "/admin",
        id: "category",
        parent: "categories",
      },
      {
        path: "/grants/:categoryId?",
        name: "Ayudas",
        icon: GrantIcon,
        component: PublicGrants,
        layout: "/admin",
        id: "grants",
      },
      {
        path: "/grant/:id?",
        name: "Nueva Ayuda",
        icon: GrantIcon,
        component: PublicGrant,
        layout: "/admin",
        id: "grant",
        parent: "grants",
      },
      {
        path: "/notifications",
        name: "Notificaciones",
        icon: Notifications,
        component: NotificationsPage,
        layout: "/admin",
        id: "notifications",
      },
      {
        path: "/search/:textToSearch?",
        name: "Buscar",
        icon: SearchIcon,
        component: PublicSearch,
        layout: "/admin",
        id: "search",
      },
      {
        path: "/list-grants/:type",
        name: "Ayudas ",
        icon: GrantIcon,
        component: ListGrants,
        layout: "/admin",
        id: "list-grants",
      },
    ];
  }
}

export default dashboardRoutes;
