import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
//import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { rep_getUsers } from "data/repository";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  margin: {
    marginLeft: "15px",
  },
  inputSearch: {
    width: "75%",
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = async () => {
    const usersData = await rep_getUsers();

    setUsers(usersData);
  };

  if (users && Object.keys(users).length > 0) {
    const dataToTable = [];
    users.map((value) =>
      dataToTable.push([
        value.name,
        value.first_surname + " " + value.second_surname,
        value.email,
      ])
    );

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Usuarios</h4>
              <p className={classes.cardCategoryWhite}>Usuarios del sistema</p>
            </CardHeader>
            <CustomInput
              formControlProps={{
                className:
                  classes.margin +
                  " " +
                  classes.search +
                  " " +
                  classes.inputSearch,
              }}
              inputProps={{
                placeholder: "Buscar Usuario",
                inputProps: {
                  "aria-label": "Buscar",
                },
                //onChange={}
              }}
            />
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "Nombre",
                  "Apellidos",
                  "Correo Electrónico",
                  "Acciones",
                ]}
                tableData={dataToTable}
                tableActions={[
                  '<a href="#">Editar</a>',
                  '<a href="#">Borrar</a>',
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  } else {
    return <div>Cargando...</div>;
  }
}
