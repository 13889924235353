import React, { useEffect, useState, useRef } from "react";
import {
  rep_getGrant,
  rep_imageGrant,
  config,
  rep_authHeaders,
} from "data/repository";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardImage from "components/Card/CardImage.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import axios from "axios";

import { useHistory } from "react-router-dom";

import noImage from "assets/img/no_image.jpg";
import { useParams } from "react-router-dom";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Checkbox } from "@material-ui/core";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Editor } from "@tinymce/tinymce-react";

const styles = {
  cardGrantWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputImage: {
    display: "none",
  },
  textEditor: {
    paddingTop: "25px !important",
    paddingBottom: "25px !important",
  },
};

const useStyles = makeStyles(styles);

export default function Grant() {
  let { id } = useParams();
  const classes = useStyles();
  const [grant, setGrant] = useState({
    id: id,
    id_category: "",
    name: "",
    description: "",
    amount: "",
    begin_date: new Date(),
    end_date: new Date(),
    call: "",
    req: "",
    image: noImage,
    on_cover: false,
    video: "",
    cover_image: noImage,
    finished: false,
  });
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newImage, setImage] = useState(null);
  const [newCoverImage, setCoverImage] = useState(null);
  const [saving, setSaving] = useState(false);
  const [categories, setCategories] = useState({});

  const history = useHistory();

  const [beginDate, setBeginDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const editorRef = useRef(null);

  useEffect(() => {
    if (id !== undefined) {
      setEdit(true);
      getGrantData();
      getCategoriesData();
    } else {
      getCategoriesData();
    }
  }, []);

  const getGrantData = async () => {
    const grantData = await rep_getGrant(id);

    setGrant(grantData);
    setBeginDate(grantData.begin_date);
    setEndDate(grantData.end_date);
  };

  const getCategoriesData = async () => {
    axios
      .get(config.API_URL + "admin/ccg_category/all", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setCategories(response.data), setLoading(false);
      });
  };

  const saveImage = async (grantId, type) => {
    const formData = new FormData();
    if (type == "normal") {
      formData.append("file", newImage);
    } else {
      formData.append("file", newCoverImage);
    }
    formData.append("id", grantId);
    formData.append("type", type);

    rep_imageGrant(formData);
  };

  const handleClickSaveGrant = async () => {
    if (saving) {
      return;
    }

    setSaving(true);

    if (edit) {
      axios
        .put(config.API_URL + "admin/ccg_grant/update", grant, {
          headers: rep_authHeaders(),
        })
        .then((response) => {
          saveImage(JSON.parse(response.data).id, "normal"),
            saveImage(JSON.parse(response.data).id, "cover"),
            setSaving(false);
        });
    } else {
      axios
        .post(config.API_URL + "admin/ccg_grant/store", grant, {
          headers: rep_authHeaders(),
        })
        .then((response) => {
          saveImage(JSON.parse(response.data).id, "normal"),
            saveImage(JSON.parse(response.data).id, "cover"),
            setSaving(false);
          history.push("/admin/grants/");
          history.go(0);
        });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);

      grant.image = URL.createObjectURL(e.target.files[0]);
      setGrant(grant);
    }
  };

  const handleCoverImageChange = (e) => {
    if (e.target.files[0]) {
      setCoverImage(e.target.files[0]);

      grant.cover_image = URL.createObjectURL(e.target.files[0]);
      setGrant(grant);
    }
  };

  const handleEditorChange = (value) => {
    grant.description = value;
    setGrant(grant);
  };

  if (!loading) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Crear/Editar Ayuda</h4>
                <p className={classes.cardGrantWhite}>Crea/Edita una ayuda</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Select
                      defaultValue={grant.id_category}
                      onChange={(e) => {
                        (grant.id_category = e.target.value), setGrant(grant);
                      }}
                    >
                      {categories.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombre"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: "" + grant.name,
                        onChange: (e) => {
                          (grant.name = e.target.value), setGrant(grant);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Incentivo"
                      id="cantidad"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: "" + grant.amount,
                        onChange: (e) => {
                          (grant.amount = e.target.value), setGrant(grant);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CardImage profile>
                      <input
                        accept="image/*"
                        className={classes.inputImage}
                        id="icon-button-file"
                        type="file"
                        onChange={handleImageChange}
                      />
                      <label htmlFor="icon-button-file">
                        <img
                          src={
                            grant.image
                              ? grant.image.includes("blob") ||
                                grant.image.includes("data")
                                ? grant.image
                                : grant.image +
                                  "?" +
                                  moment().format("grant_image_DDMMYYYYhhmmss")
                              : noImage
                          }
                          alt="Selecciona una imagen"
                        />
                      </label>
                    </CardImage>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.textEditor}>
                      <Editor
                        apiKey="95ex9hba7ffhh881ekq5pe4lp1jd7g0m9qsike2us6sn3pj3"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={grant.description}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        value={moment(beginDate)}
                        onChange={(datatime) => {
                          (grant.begin_date = datatime.toDate()),
                            setBeginDate(datatime.toDate()),
                            setGrant(grant);
                        }}
                        autoOk={true}
                        label="Fecha de Inicio"
                        showTodayButton
                        format="DD/MM/YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        value={moment(endDate)}
                        onChange={(datatime) => {
                          (grant.end_date = datatime.toDate()),
                            setEndDate(datatime.toDate()),
                            setGrant(grant);
                        }}
                        label="Fecha de Fin"
                        showTodayButton
                        format="DD/MM/YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="URL Convocatoria"
                      id="call"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: "" + grant.call,
                        onChange: (e) => {
                          (grant.call = e.target.value), setGrant(grant);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="URL Solicitud"
                      id="req"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: "" + grant.req,
                        onChange: (e) => {
                          (grant.req = e.target.value), setGrant(grant);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="URL Vídeo"
                      id="video"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: "" + grant.video,
                        onChange: (e) => {
                          (grant.video = e.target.value), setGrant(grant);
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      label="Poner en portada"
                      control={
                        <Checkbox
                          id="on_cover"
                          defaultChecked={grant.on_cover}
                          onChange={(e) => {
                            (grant.on_cover = e.target.checked),
                              setGrant(grant);
                          }}
                        />
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CardImage profile>
                      <input
                        accept="image/*"
                        className={classes.inputImage}
                        id="icon-button-file-cover"
                        type="file"
                        onChange={handleCoverImageChange}
                      />
                      <label htmlFor="icon-button-file-cover">
                        <img
                          src={
                            grant.cover_image
                              ? grant.cover_image.includes("blob") ||
                                grant.cover_image.includes("data")
                                ? grant.cover_image
                                : grant.cover_image +
                                  "?" +
                                  moment().format("grant_cover_DDMMYYYYhhmmss")
                              : noImage
                          }
                          alt="Selecciona una imagen"
                        />
                      </label>
                    </CardImage>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={handleClickSaveGrant}
                  color="primary"
                  disabled={saving}
                >
                  {saving ? "Guardando..." : "Guardar Ayuda"}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return <div>Cargando...</div>;
  }
}

Grant.propTypes = {
  grantId: PropTypes.object,
};
