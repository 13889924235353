import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { rep_authHeaders, config } from "data/repository";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import Arrow from "@material-ui/icons/ArrowRight";

import axios from "axios";

import NoImage from "assets/img/no_image.jpg";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  textAlignRight: {
    textAlign: "right",
  },
  inputSearch: {
    width: "100%",
  },
  buttonNew: {
    marginTop: "27px",
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  muiTable: {
    width: "100%",
    display: "table",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  imagePreview: {
    maxWidth: "100%",
    height: "auto",
    display: "block",
    width: "100%",
  },
  feature_box_7: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    marginBottom: "30px",
    padding: 0,
    "& a": {
      color: "#ff8800",
    },
    "&:hover $cuerpo": {
      transform: "translateY(0%)",
    },
  },
  cuerpo: {
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgba(190, 152, 38, 0.8)",
    height: "100%",
    fontSize: "calc(0.8rem + 0.5vw)",
    borderLeft: "4px solid #ba0c2f",
    // -webkit-transition: "all 0.3s ease",
    // -moz-transition: "all 0.3s ease",
    // -ms-transition: "all 0.3s ease",
    // -o-transition: "all 0.3s ease",
    transition: "all 0.3s ease",
    // -webkit-transform: "translateY(75%)",
    transform: "translateY(75%)",
    "&:hover": {
      transform: "translateY(0%)",
    },
  },
  shadow: {
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)!important",
  },
  //   .feature-box-7 .body .info-box .text {
  //     color: #fff; }
  //   .feature-box-7 .body .info-box .title {
  //     color: #fff;
  //     font-size: 16px;
  //     font-weight: 700;
  //     margin-bottom: 24px;
  //     line-height: 2; }
  // .feature-box-7:hover .body {
  //   transform: translateY(0%); }
  //   .feature-box-7:hover .body .title:after {
  //     -webkit-transform: scale(1, 1);
  //     -moz-transform: scale(1, 1);
  //     -ms-transform: scale(1, 1);
  //     -o-transform: scale(1, 1);
  //     transform: scale(1, 1); }
  // .feature-box-7 a {
  //   color: #ff8800; }
  media_box: {
    opacity: "0.8",
    // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: "alpha(opacity=80)",
    // -webkit-transform: scale(1.05, 1.05);
    // -ms-transform: scale(1.05, 1.05);
    transform: "scale(1.05, 1.05)",
    // -webkit-transition-timing-function: ease-out;
    transitionTimingFunction: "ease-out",
    // -webkit-transition-duration: 250ms;
    transitionDuration: "250ms",
    width: "100%",
  },
  padding: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingBottom: "5%",
  },
  content: {
    paddingTop: "30px",
    fontSize: "1.2vw",
    ["@media (max-width:959px)"]: {
      fontSize: "1.8vw",
    },
    ["@media (max-width:599px)"]: {
      fontSize: "3.6vw",
    },
  },
  grantLink: {
    color: "#ba0c2f",
  },
  sectionHeading: {
    fontSize: "35px",
    lineHeight: "1em",
    marginBottom: "20px",
    marginTop: "40px",
    marginLeft: "10px",
    paddingBottom: "5px",
    paddingLeft: "30px",
    position: "relative",
    color: "#0d2b45",
    "&::before": {
      content: "''",
      display: "block",
      width: "6px",
      height: "30px",
      backgroundColor: "#ba0c2f",
      position: "absolute",
      left: 0,
      top: 0,
    },
    "&::after": {
      content: "''",
      display: "block",
      width: "6px",
      height: "30px",
      backgroundColor: "#be9826",
      position: "absolute",
      left: "8px",
      top: "5px",
    },
  },
  icons: {
    // width: "80px",
    // height: "80px",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: "17px",
    fontSize: "30px",
    color: "#ba0c2f",
  },
  rsIconInfo: {
    marginBottom: "-20px",
    marginLeft: "20px",
    color: "#222",
    fontSize: "11px",
    // padding: "10px 0",
  },
  infoIcon: {
    display: "table-cell",
    position: "relative",
  },
  infoText: {
    display: "table-cell",
    paddingLeft: "20px",
    position: "relative",
    verticalAlign: "middle",
    fontSize: "16px",
  },
};

const useStyles = makeStyles(styles);

export default function Categories() {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(true);
  const [categories, setCategories] = useState({});
  const [activeGrants, setActiveGrants] = useState({});
  const [inactiveGrants, setInactiveGrants] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (loadingData) {
      getCategoriesData();
    }
  }, []);

  const getCategoriesData = () => {
    axios
      .get(config.API_URL + "admin/ccg_category/all", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setCategories(response.data), getAllGrantsData();
      });
  };

  const getAllGrantsData = () => {
    axios
      .get(config.API_URL + "admin/ccg_grant/limit-active/10/0", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setActiveGrants(response.data), getAllInactiveGrantsData();
      });
  };

  const getAllInactiveGrantsData = () => {
    axios
      .get(config.API_URL + "admin/ccg_grant/limit-inactive/10/0", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setInactiveGrants(response.data), setLoadingData(false);
      });
  };

  const handleGoCategory = (id) => {
    history.push("/admin/grants/" + id);
    history.go(0);
  };

  const handleViewGrant = (id) => {
    history.push("/admin/grant/" + id);
    history.go(0);
  };

  const handleGoActive = () => {
    history.push("/admin/list-grants/all-active");
    history.go(0);
  };

  const handleGoInactive = () => {
    history.push("/admin/list-grants/all-inactive");
    history.go(0);
  };

  return (
    <div>
      {loadingData ? (
        <div>Cargando...</div>
      ) : (
        <GridContainer>
          <GridItem xs={12}>
            <h2 className={classes.sectionHeading}>Categorias</h2>
          </GridItem>
          {categories.map((value) => {
            return (
              <GridItem key={value.id} xs={12} sm={6} md={4}>
                <div className={classes.padding}>
                  <Button
                    className={classes.feature_box_7}
                    // color="primary"
                    onClick={() => handleGoCategory(value.id)}
                  >
                    <div className={classes.media_box}>
                      <img
                        className={classes.imagePreview}
                        src={
                          value.image !== null
                            ? value.image +
                              "?" +
                              moment().format("DDMMYYYYhhmmss")
                            : NoImage
                        }
                      />
                    </div>
                    <div className={classes.cuerpo}>
                      <div className={classes.content}>{value.name}</div>
                    </div>
                  </Button>
                </div>
              </GridItem>
            );
          })}
          <GridItem xs={12}>
            <h2 className={classes.sectionHeading}>Las ayudas más recientes</h2>
          </GridItem>
          {activeGrants.map((value) => {
            return (
              <GridItem key={value.id} xs={12} sm={12} md={12}>
                <div className={classes.rsIconInfo}>
                  <div className={classes.infoIcon}>
                    <Arrow className={classes.icons} />
                  </div>
                  <div className={classes.infoText}>
                    <Link
                      className={classes.grantLink}
                      onClick={() => handleViewGrant(value.id)}
                    >
                      {value.name}
                    </Link>
                  </div>
                </div>
              </GridItem>
            );
          })}
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoText}>
                <br />
                <Link
                  className={classes.grantLink}
                  onClick={() => handleGoActive()}
                >
                  VER TODAS
                </Link>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12}>
            <h2 className={classes.sectionHeading}>Ayudas expiradas</h2>
          </GridItem>
          {inactiveGrants.map((value) => {
            return (
              <GridItem key={value.id} xs={12} sm={12} md={12}>
                <div className={classes.rsIconInfo}>
                  <div className={classes.infoIcon}>
                    <Arrow className={classes.icons} />
                  </div>
                  <div className={classes.infoText}>
                    <Link
                      className={classes.grantLink}
                      onClick={() => handleViewGrant(value.id)}
                    >
                      {value.name}
                    </Link>
                  </div>
                </div>
              </GridItem>
            );
          })}
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoText}>
                <br />
                <Link
                  className={classes.grantLink}
                  onClick={() => handleGoInactive()}
                >
                  VER TODAS
                </Link>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
