import { useState, useEffect } from "react";

export const usePasswordValidation = ({
  firstPassword = "",
  secondPassword = "",
  requiredLength = 0,
}) => {
  const [validLength1, setValidLength1] = useState(null);
  const [validLength2, setValidLength2] = useState(null);
  //   const [hasNumber, setHasNumber] = useState(null);
  //   const [upperCase, setUpperCase] = useState(null);
  //   const [lowerCase, setLowerCase] = useState(null);
  //   const [specialChar, setSpecialChar] = useState(null);
  const [match, setMatch] = useState(null);

  useEffect(() => {
    setValidLength1(firstPassword.length);
    setValidLength2(secondPassword.length);
    // setUpperCase(firstPassword.toLowerCase() !== firstPassword);
    // setLowerCase(firstPassword.toUpperCase() !== firstPassword);
    // setHasNumber(/\d/.test(firstPassword));
    setMatch(firstPassword && firstPassword === secondPassword);
    // setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(firstPassword));
  }, [firstPassword, secondPassword, requiredLength]);
  //   return [validLength, hasNumber, upperCase, lowerCase, match, specialChar];
  return [validLength1, validLength2, match];
};
