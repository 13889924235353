import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { rep_deleteGrant, rep_authHeaders, config } from "data/repository";
import { useHistory } from "react-router-dom";
import Moment from "moment";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  margin: {
    marginLeft: "15px",
  },
  inputSearch: {
    width: "75%",
  },
  buttonNew: {
    marginTop: "27px",
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  muiTable: {
    width: "100%",
    display: "table",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  imagePreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
};

const useStyles = makeStyles(styles);

export default function Grants() {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(true);
  const [grants, setGrants] = useState({});
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [grantDeleteId, setGrantDeleteId] = useState(null);

  useEffect(() => {
    if (loadingData) {
      getGrantsData();
    }
  }, []);

  const getGrantsData = () => {
    axios
      .get(config.API_URL + "admin/ccg_grant/all", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setGrants(response.data), setLoadingData(false);
      });
  };

  const handleNewGrant = () => {
    history.push("/admin/grant");
    history.go(0);
  };

  const handleEditGrant = (id) => {
    history.push("/admin/grant/" + id);
    history.go(0);
  };

  const handleDeleteOpen = (id) => {
    setGrantDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await rep_deleteGrant(grantDeleteId);
    getGrantsData();
    setOpen(false);
  };

  return (
    <div>
      {loadingData ? (
        <div>Cargando...</div>
      ) : (
        <GridContainer>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"IMPORTANTE"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ¿Seguro que quieres ELIMINAR la ayuda?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Cancelar
              </Button>
              <Button onClick={handleDelete} color="danger">
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Ayudas</h4>
                <p className={classes.cardCategoryWhite}>Ayudas para ofertar</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={8} md={8} lg={10}>
                    <CustomInput
                      formControlProps={{
                        className: classes.inputSearch,
                      }}
                      inputProps={{
                        placeholder: "Buscar Ayuda",
                        inputProps: {
                          "aria-label": "Buscar",
                        },
                        //onChange={}
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4} lg={2}>
                    <Button
                      onClick={handleNewGrant}
                      className={(classes.buttonLink, classes.buttonNew)}
                    >
                      Nueva Ayuda
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardBody>
                <div className={classes.tableWrapper}>
                  <table className={classes.muiTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Fecha de inicio</TableCell>
                        <TableCell>Fecha de fin</TableCell>
                        <TableCell>En portada</TableCell>
                        <TableCell>Categoría</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {grants.map((value) => {
                        return (
                          <TableRow key={value.id}>
                            <TableCell>{value.name}</TableCell>
                            <TableCell>{value.amount}</TableCell>
                            <TableCell>
                              {Moment(value.begin_date).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell>
                              {Moment(value.end_date).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell>
                              {value.on_cover ? <CheckIcon /> : <ClearIcon />}
                            </TableCell>
                            <TableCell>{value.category.name}</TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={() => handleEditGrant(value.id)}
                              >
                                Editar
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => handleDeleteOpen(value.id)}
                              >
                                Eliminar
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
