import React from "react";
import LoginForm from "views/Users/Login.js";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
//import Navbar from "components/Navbars/Menu.js";
import Footer from "components/Footer/Footer.js";

import Logo from "assets/img/LogoClubCamara.png";

import styles from "assets/jss/material-dashboard-react/layouts/publicStyle.js";

let ps;

const useStyles = makeStyles(styles);

export default function Index(/*{ ...rest }*/) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    //window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      // window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel} ref={mainPanel}>
        <div className="login-wrapper">
          <img src={Logo} width="300" className={classes.cardLogo} />
          <LoginForm />
        </div>
        <Footer />
      </div>
    </div>
  );
}
