import React from "react";
import LoginForm from "views/Users/Login.js";
import Footer from "components/Footer/Footer.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Logo from "assets/img/LogoClubCamara.png";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardLogo: {
    maxWidth: "100%",
  },
  redColor: {
    backgroundColor: "#ba0c2f",
    "&:hover, &:focus": {
      backgroundColor: "#be9826",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Login() {
  const classes = useStyles();

  return (
    <div>
      <div className="login-wrapper">
        <img src={Logo} width="300" className={classes.cardLogo} />
        <LoginForm />
      </div>
      <Footer />
    </div>
  );
}
