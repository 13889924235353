import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

// core components
import Index from "layouts/Index.js";
import Admin from "layouts/Admin.js";
import Login from "components/Login/Login.js";
import Reset from "components/Login/Reset.js";
import Remember from "components/Login/RememberPass.js";

import PrivateRoute from "PrivateRoute";
import PublicRoute from "PublicRoute";

import "assets/css/ccg_core_front.css?v=1.10.0";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute restricted={true} component={Index} path="/" exact />
        <PublicRoute restricted={true} component={Index} path="/public" />
        <PublicRoute restricted={true} component={Login} path="/signin" exact />
        <PublicRoute
          restricted={true}
          component={Reset}
          path="/reset/:id"
          exact
        />
        <PublicRoute
          restricted={true}
          component={Remember}
          path="/remember"
          exact
        />
        <PrivateRoute component={Admin} path="/admin" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
