import React, { useState } from "react";
import { rep_reset } from "data/repository";
import { usePasswordValidation } from "hooks/usePasswordValidation";
import { useParams, useHistory } from "react-router-dom";
import Footer from "components/Footer/Footer.js";

import "./Login.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import Error from "@material-ui/icons/Error";

import Logo from "assets/img/LogoClubCamara.png";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardLogo: {
    maxWidth: "100%",
  },
  redColor: {
    backgroundColor: "#ba0c2f",
    "&:hover, &:focus": {
      backgroundColor: "#be9826",
    },
  },
  error: {
    // width: "100px",
    fontWeight: "bold",
    color: "#ff0000",
    paddingTop: "15px",
  },
  text: {
    padding: "10px",
  },
};

const useStyles = makeStyles(styles);

async function resetPass(credentials) {
  return rep_reset(credentials);
}

function App() {
  let { id } = useParams();
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const history = useHistory();

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [validLength1, validLength2, match] = usePasswordValidation({
    firstPassword: password.firstPassword,
    secondPassword: password.secondPassword,
  });

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  const handleClickSavePass = async (e) => {
    e.preventDefault();

    if (!id) {
      history.push("/signin");
      history.go(0);
    }

    if (saving) {
      return;
    }

    setSaving(true);

    await resetPass({
      id: id,
      password: password.firstPassword,
    });
    setSaving(false);
    history.push("/signin");
    history.go(0);
  };
  return (
    <div className="App">
      <div className="login-wrapper">
        <img src={Logo} width="300" className={classes.cardLogo} />
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    background: "linear-gradient(60deg, #ba0c2f, #9f102c)",
                  }}
                >
                  <h4 className={classes.cardTitleWhite}>Cambiar contraseña</h4>
                  <p className={classes.cardCategoryWhite}>
                    Por favor, introduzca su nueva contraseña
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {/* <input onChange={setFirst} type="text" /> */}
                      <CustomInput
                        labelText="Nueva Contraseña"
                        id="password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setFirst(e),
                          type: "password",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {/* <input onChange={setSecond} type="text" /> */}
                      <CustomInput
                        labelText="Confirmar Contraseña"
                        id="check_password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setSecond(e),
                          type: "password",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={6} style={{ width: "100px" }}>
                      {!match && (validLength1 > 0 || validLength2) > 0 ? (
                        <div className={classes.error}>
                          Las contraseñas no coinciden
                        </div>
                      ) : (
                        ""
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button
                    onClick={handleClickSavePass}
                    type="submit"
                    className={classes.redColor}
                    disabled={!match || saving}
                  >
                    {saving ? "Guardando..." : "Guardar"}
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      </div>
      <Footer />
    </div>
  );
}
export default App;
