import {
  defaultFont,
  container,
  // primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px",
  },
  left: {
    float: "left!important",
    display: "block",
    paddingLeft: "30px",
    ["@media (max-width:959px)"]: {
      textAlign: "center",
      float: "none !important",
      paddingLeft: "0",
    },
  },
  right: {
    float: "right!important",
    display: "block",
    ["@media (max-width:959px)"]: {
      textAlign: "center",
      float: "none !important",
    },
  },
  company: {
    paddingRight: "30px",
    ["@media (max-width:959px)"]: {
      paddingRight: "0",
    },
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "table",
  },
  centerCategories: {
    paddingLeft: "60px",
    ["@media (max-width:959px)"]: {
      paddingLeft: "40%",
    },
    ["@media (max-width:599px)"]: {
      paddingLeft: "60px",
    },
  },
  footer: {
    overflowX: "hidden",
    position: "relative",
    bottom: "0",
    borderTop: "1px solid " + grayColor[11],
    padding: "100px 0 0",
    backgroundColor: "#ba0c2f",
    // height: "250px",
    color: "#fff",
    ...defaultFont,
  },
  // triangle: {
  //   "&::after": {
  //     content: "''",
  //     width: 0,
  //     height: 0,
  //     borderStyle: "solid",
  //     borderWidth: "0 800px 300px 0",
  //     borderColor: "transparent transparent #fff transparent",
  //     left: 0,
  //     bottom: 0,
  //     position: "absolute",
  //   },
  // },
  logo: {
    padding: "10px 50px",
    "& img": {
      maxWidth: "300px",
      width: "100%",
      ["@media (max-width:959px)"]: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  container,
  a: {
    color: "#fff",
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  a2: {
    color: "#fff",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover": {
      color: "#fff",
      textDecoration: "underline",
    },
  },
  a2Little: {
    color: "#fff",
    textDecoration: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    "&:hover": {
      color: "#fff",
      textDecoration: "underline",
    },
  },
  a3: {
    color: "#000",
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  container2: {
    backgroundColor: "#be9826",
    maxWidth: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    height: "fit-content",
    position: "relative",
    marginBottom: "-75px",
    fontSize: "16px",
    padding: "15px",
    zIndex: 1,
  },
  icons: {
    width: "80px",
    height: "80px",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: "17px",
    fontSize: "50px",
    color: "#222",
  },
  socialIcons: {
    width: "50px",
    height: "50px",
    paddingTop: "17px",
  },
  info: {
    display: "inline",
  },
  text: {
    display: "inline",
    fontSize: "12px",
  },
  rsIconInfo: {
    marginBottom: "15px",
    color: "#222",
  },
  infoIcon: {
    display: "table-cell",
    position: "relative",
  },
  infoText: {
    display: "table-cell",
    paddingLeft: "20px",
    position: "relative",
    verticalAlign: "middle",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "3px",
  },
};
export default footerStyle;
