export const login = (userToken) => {
  localStorage.setItem("token", JSON.stringify(userToken));
};

export const logout = () => {
  localStorage.removeItem("token");
};

export const isLogged = () => {
  if (localStorage.getItem("token")) {
    return true;
  }

  return false;
};

export const isAllowed = (needed) => {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return (
    userToken !== null &&
    userToken.rol_id !== undefined &&
    needed == userToken.rol_id
  );
};

export const getToken = () => {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.access_token;
};

export const userId = () => {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken?.user_id;
};
