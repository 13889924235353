import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react.js";

const cardImageStyle = {
  cardImage: {
    "&$cardImageProfile img": {
      width: "100%",
      height: "auto",
    },
  },
  cardImageProfile: {
    maxWidth: "130px",
    maxHeight: "130px",
    margin: "auto",
    overflow: "hidden",
    padding: "0",
    boxShadow:
      "0 16px 38px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.56), 0 4px 25px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    "&$cardImagePlain": {
      marginTop: "0",
    },
  },
  cardImagePlain: {},
};

export default cardImageStyle;
