import React, { useState } from "react";
import { rep_remember } from "data/repository";
import Footer from "components/Footer/Footer.js";

import "./Login.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import Error from "@material-ui/icons/Error";

import Logo from "assets/img/LogoClubCamara.png";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardLogo: {
    maxWidth: "100%",
  },
  redColor: {
    backgroundColor: "#ba0c2f",
    "&:hover, &:focus": {
      backgroundColor: "#be9826",
    },
  },
  error: {
    // width: "100px",
    fontWeight: "bold",
    color: "#ff0000",
    paddingTop: "15px",
  },
  text: {
    padding: "10px",
  },
};

const useStyles = makeStyles(styles);

async function sendEmail(email) {
  return rep_remember(email);
}

export default function RememberPass() {
  const classes = useStyles();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const [email, setEmail] = useState();

  const handleClickSendEmail = async (e) => {
    e.preventDefault();

    setSending(true);

    var data = await sendEmail({ email: email });

    setSending(false);

    if (data.message == "Email sent!") {
      if (!success) {
        setSuccess(true);
        setTimeout(function () {
          setSuccess(false);
        }, 6000);
      }
    } else {
      if (!alert) {
        setAlertMessage(data.message);
        setAlert(true);
        setTimeout(function () {
          setAlert(false);
        }, 6000);
      }
      setEmail();
    }
  };

  return (
    <div className="App">
      <div className="login-wrapper">
        <img src={Logo} width="300" className={classes.cardLogo} />
        <form>
          <GridContainer>
            <Snackbar
              place="tc"
              color="success"
              icon={AddAlert}
              message="Se ha enviado un correo electrónico a la cuenta proporcionada con un enlace para poder cambiar su contraseña."
              open={success}
              closeNotification={() => setSuccess(false)}
              close
            />
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message={
                alertMessage +
                ", por favor, póngase en contacto con clubcamara@camaragranada.org."
              }
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  color="primary"
                  style={{
                    background: "linear-gradient(60deg, #ba0c2f, #9f102c)",
                  }}
                >
                  <h4 className={classes.cardTitleWhite}>
                    Recuperar contraseña
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    Por favor, introduzca su cuenta de correo electrónico
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {/* <input onChange={setFirst} type="text" /> */}
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setEmail(e.target.value),
                          type: "text",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button
                    onClick={handleClickSendEmail}
                    type="submit"
                    className={classes.redColor}
                    disabled={sending}
                  >
                    {sending ? "Enviando..." : "Enviar"}
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      </div>
      <Footer />
    </div>
  );
}
