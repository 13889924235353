import { getToken } from "login";
import axios from "axios";

const DEV_URL = {
  SERV_URL: "https://api.camaragranada.org/",
  API_URL: "https://api.camaragranada.org/api/",
  // SERV_URL: "http://localhost:8000/",
  // API_URL: "http://localhost:8000/api/",
};
const PROD_URL = {
  SERV_URL: "https://api.camaragranada.org/",
  API_URL: "https://api.camaragranada.org/api/",
};

export const ROLES = {
  COMPANY: "80fd2928-96eb-4b1c-b366-5aa6660e8e0e",
  ADMIN: "04162788-f58b-4eab-9fdb-6630df6f79bd",
  SUPERADMIN: "a8877af9-bc54-4df8-a2cd-7f4a4553c830",
};

export const config =
  process.env.NODE_ENV === "development" ? DEV_URL : PROD_URL;

// Headers
export const rep_headers = () => {
  return {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
};

export const rep_authHeaders = () => {
  return {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: "Bearer ".concat(getToken()),
  };
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      // if ([401, 403].includes(response.status)) {
      //   // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      // } else if ([422].includes(response.status)) {
      //   history.push("/");
      //   history.go(0);
      // }

      const error = "Error: " + ((data && data.message) || response.statusText);
      console.log(error);
      return error;
    }

    return data;
  });
}

// Authentication
export const rep_login = (credentials) => {
  return fetch(config.API_URL + "auth/login", {
    method: "POST",
    headers: rep_headers(),
    body: JSON.stringify(credentials),
  }).then(/*(data) => data.json()*/ handleResponse);
};

export const rep_logout = () => {
  return axios.get(config.API_URL + "auth/logout", {
    headers: rep_authHeaders(),
  });
};

export const rep_reset = (credentials) => {
  return fetch(config.API_URL + "auth/reset", {
    method: "POST",
    headers: rep_headers(),
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
};

export const rep_remember = (email) => {
  return fetch(config.API_URL + "mail/gcc/send-reset", {
    method: "POST",
    headers: rep_headers(),
    body: JSON.stringify(email),
  }).then((data) => data.json());
};

// User CRUD
export const rep_getUser = async (userId) => {
  return await fetch(config.API_URL + "auth/user/" + userId, {
    method: "POST",
    headers: rep_authHeaders(),
  }).then((data) => data.json());
};

export const rep_getUsers = async () => {
  return await fetch(config.API_URL + "auth/users", {
    method: "GET",
    headers: rep_authHeaders(),
  }).then((data) => data.json());
};

// Categories CRUD
export const rep_getCategories = async () => {
  return await fetch(config.API_URL + "admin/ccg_category/all", {
    method: "GET",
    headers: rep_authHeaders(),
  }).then((data) => data.json());
};

export const rep_getCategory = async (categoryId) => {
  return await fetch(config.API_URL + "admin/ccg_category/show/" + categoryId, {
    method: "GET",
    headers: rep_authHeaders(),
  }).then((data) => data.json());
};

export const rep_storeCategory = async (category) => {
  return await fetch(config.API_URL + "admin/ccg_category/store", {
    method: "POST",
    headers: rep_authHeaders(),
    body: JSON.stringify(category),
  }).then((data) => data.json());
};

export const rep_updateCategory = async (category) => {
  return await fetch(config.API_URL + "admin/ccg_category/update", {
    method: "PUT",
    headers: rep_authHeaders(),
    body: JSON.stringify(category),
  }).then((data) => data.json());
};

export const rep_deleteCategory = async (categoryId) => {
  return await fetch(
    config.API_URL + "admin/ccg_category/destroy/" + categoryId,
    {
      method: "DELETE",
      headers: rep_authHeaders(),
    }
  ).then((data) => data.json());
};

export const rep_reorderCategory = async (category) => {
  return await fetch(config.API_URL + "admin/ccg_category/reorder", {
    method: "POST",
    headers: rep_authHeaders(),
    body: JSON.stringify(category),
  }).then((data) => data.json());
};

export const rep_imageCategory = async (imageData) => {
  return await fetch(config.API_URL + "admin/ccg_category/saveimage", {
    method: "POST",
    headers: {
      Authorization: "Bearer ".concat(getToken()),
    },
    body: imageData,
  }).then((data) => data.json());
};

// Grants CRUD
export const rep_getGrants = async () => {
  return await fetch(config.API_URL + "admin/ccg_grant/all", {
    method: "GET",
    headers: rep_authHeaders(),
  }).then((data) => data.json());
};

export const rep_getGrant = async (grantId) => {
  return await fetch(config.API_URL + "admin/ccg_grant/show/" + grantId, {
    method: "GET",
    headers: rep_authHeaders(),
  }).then((data) => data.json());
};

export const rep_deleteGrant = async (grantId) => {
  return await fetch(config.API_URL + "admin/ccg_grant/destroy/" + grantId, {
    method: "DELETE",
    headers: rep_authHeaders(),
  }).then((data) => data.json());
};

export const rep_imageGrant = async (imageData) => {
  return await fetch(config.API_URL + "admin/ccg_grant/saveimage", {
    method: "POST",
    headers: {
      Authorization: "Bearer ".concat(getToken()),
    },
    body: imageData,
  }).then((data) => data.json());
};
