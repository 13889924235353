import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  rep_deleteCategory,
  rep_reorderCategory,
  rep_authHeaders,
  config,
} from "data/repository";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";

import NoImage from "assets/img/no_image.jpg";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  textAlignRight: {
    textAlign: "right",
  },
  inputSearch: {
    width: "100%",
  },
  buttonNew: {
    marginTop: "27px",
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  muiTable: {
    width: "100%",
    display: "table",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  imagePreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
};

const useStyles = makeStyles(styles);

export default function Categories() {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(true);
  const [categories, setCategories] = useState({});
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [categoryDeleteId, setCategoryDeleteId] = useState(null);

  useEffect(() => {
    if (loadingData) {
      getCategoriesData();
    }
  }, []);

  const getCategoriesData = () => {
    axios
      .get(config.API_URL + "admin/ccg_category/all", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setCategories(response.data), setLoadingData(false);
      });
  };

  const handleNewCategory = () => {
    history.push("/admin/category");
    history.go(0);
  };

  const handleEditCategory = (id) => {
    history.push("/admin/category/" + id);
    history.go(0);
  };

  const handleDeleteOpen = (id) => {
    setCategoryDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await rep_deleteCategory(categoryDeleteId);
    getCategoriesData();
    setOpen(false);
  };

  const handleUpCategory = async (id, order) => {
    var category = { id: id, order: order - 1 };
    rep_reorderCategory(category);
    getCategoriesData();
  };

  const handleDownCategory = async (id, order) => {
    var category = { id: id, order: order + 1 };
    rep_reorderCategory(category);
    getCategoriesData();
  };

  return (
    <div>
      {loadingData ? (
        <div>Cargando...</div>
      ) : (
        <GridContainer>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"IMPORTANTE"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ¿Seguro que quieres ELIMINAR la categoría?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Cancelar
              </Button>
              <Button onClick={handleDelete} color="danger">
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Categorías</h4>
                <p className={classes.cardCategoryWhite}>
                  Categorías para ayudas
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={8} md={8} lg={10}>
                    <CustomInput
                      formControlProps={{
                        className: classes.inputSearch,
                      }}
                      inputProps={{
                        placeholder: "Buscar Categoría",
                        inputProps: {
                          "aria-label": "Buscar",
                        },
                        //onChange={}
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4} lg={2}>
                    <Button
                      onClick={handleNewCategory}
                      className={(classes.buttonLink, classes.buttonNew)}
                    >
                      Nueva Categoría
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardBody>
                <div className={classes.tableWrapper}>
                  <table className={classes.muiTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Imagen</TableCell>
                        <TableCell>Orden</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categories.map((value) => {
                        return (
                          <TableRow key={value.id}>
                            <TableCell>{value.name}</TableCell>
                            <TableCell>
                              <img
                                className={classes.imagePreview}
                                src={
                                  value.image !== null
                                    ? value.image +
                                      "?" +
                                      moment().format("DDMMYYYYhhmmss")
                                    : NoImage
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {value.order}
                              <IconButton
                                color="primary"
                                aria-label="Subir orden"
                                component="span"
                                disabled={value.order == 1}
                                onClick={() =>
                                  handleUpCategory(value.id, value.order)
                                }
                              >
                                <ArrowDropUpIcon />
                              </IconButton>
                              <IconButton
                                color="primary"
                                aria-label="Bajar orden"
                                component="span"
                                disabled={
                                  value.order == Object.keys(categories).length
                                }
                                onClick={() =>
                                  handleDownCategory(value.id, value.order)
                                }
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={() => handleEditCategory(value.id)}
                              >
                                Editar
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => handleDeleteOpen(value.id)}
                              >
                                Eliminar
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
