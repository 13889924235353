import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
import Link from "@material-ui/core/Link";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import TableHead from "@material-ui/core/TableHead";
// import TableBody from "@material-ui/core/TableBody";
// import TableRow from "@material-ui/core/TableRow";
// import TableCell from "@material-ui/core/TableCell";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import { rep_authHeaders, rep_getCategory, config } from "data/repository";
import { useHistory, useParams } from "react-router-dom";
import Moment from "moment";

import moment from "moment";
import axios from "axios";

import ArrowLeft from "@material-ui/icons/ArrowBackIos";
import ArrowRight from "@material-ui/icons/ArrowForwardIos";

import NoImage from "assets/img/no_image.jpg";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  margin: {
    marginLeft: "15px",
  },
  inputSearch: {
    width: "75%",
  },
  buttonNew: {
    marginTop: "27px",
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  muiTable: {
    width: "100%",
    display: "table",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  imagePreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
  bg: {
    backgroundColor: "#be9826",
  },
  contentWrap: {
    padding: "100px 0",
  },
  jumboHeading: {
    fontWeight: "600",
    position: "relative",
    fontSize: "50px",
    lineHeight: "1.2",
    ["@media (max-width:599px)"]: {
      fontSize: "26px",
      textAlign: "center",
    },
  },
  section: {
    position: "relative",
  },
  boxImage: {
    position: "relative",
    // cursor: "pointer",
    "&:hover $bodyContent": {
      transform: "translateY(-30px)",
      borderBottomColor: "#ffc003",
      boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.2)",
    },
    "&:hover $button": {
      textDecoration: "none",
      backgroundColor: "#be9826",
      color: "#fff",
    },
  },
  bodyContent: {
    backgroundColor: "#ffffff",
    marginLeft: "20px",
    marginRight: "0",
    marginTop: "-20px",
    marginBottom: "40px",
    position: "relative",
    padding: "20px 20px 20px 20px",
    transition: "all 0.3s ease",
    boxShadow: "0 5px 10px 5px rgb(0 0 0 / 5%)",
    borderLeft: "4px solid #ba0c2f",
  },
  imgFluid: {
    width: "225px",
    height: "225px",
    cursor: "pointer",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
    padding: "0 50px",
    marginTop: "-70px !important",
    position: "relative",
    zIndex: "2",
    ["@media (max-width:599px)"]: {
      padding: "0",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  icons: {
    // width: "80px",
    // height: "80px",
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: "7px",
    fontSize: "25px",
    color: "#ba0c2f",
  },
  rsIconInfo: {
    color: "#222",
    fontSize: "11px",
    marginLeft: "10px",
    paddingBottom: "10px",
  },
  infoIcon: {
    display: "table-cell",
    position: "relative",
  },
  infoText: {
    display: "table-cell",
    paddingLeft: "0px",
    position: "relative",
    verticalAlign: "middle",
    "&, & a": {
      color: "#ba0c2f",
    },
    "& a:hover,& a:focus": {
      color: "#be9826",
      textDecoration: "none",
    },
  },
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "table",
    padding: "10px 20px",
    backgroundColor: "#ba0c2f",
    color: "#fff",
    fontWeight: "bold",
    cursor: "pointer",
  },
  bodyLink: {
    "&, &:hover": {
      textDecoration: "none",
      color: "#3c4858",
      cursor: "pointer",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Grants() {
  let { categoryId } = useParams();
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(true);
  const [grants, setGrants] = useState({});
  const [category, setCategory] = useState({
    id: "",
    name: "",
    description: "",
    image: null,
    order: 0,
  });
  const history = useHistory();

  useEffect(() => {
    if (loadingData && categoryId !== undefined) {
      getGrantsData(categoryId);
    }
  }, []);

  const getGrantsData = (categoryId) => {
    axios
      .get(
        config.API_URL +
          "admin/ccg_grant/all/" +
          categoryId +
          "?onlyAssets=true",
        {
          headers: rep_authHeaders(),
        }
      )
      .then((response) => {
        setGrants(response.data),
          getCategoryData(categoryId),
          setLoadingData(false);
      });
  };

  const getCategoryData = async (categoryId) => {
    const categoryData = await rep_getCategory(categoryId);

    setCategory(categoryData);
  };

  const handleViewGrant = (id) => {
    history.push("/admin/grant/" + id);
    history.go(0);
  };

  const truncateText = (text, lenght) => {
    if (text.length > lenght) {
      text = text.substring(0, lenght);
      var lastspace = text.lastIndexOf(" ");
      text = text.substring(0, lastspace);
      text += "...";
    }
    return text;
  };

  return (
    <div>
      {loadingData && grants !== undefined ? (
        <div>Cargando...</div>
      ) : (
        <div>
          <div className={classes.rsIconInfo}>
            <div className={classes.infoIcon}>
              <ArrowLeft className={classes.icons} />
            </div>
            <div className={classes.infoText}>
              <Link
                className={classes.grantLinks}
                href={"/admin/categories/"}
                // target="_blank"
                style={{
                  fontSize: "20px",
                }}
              >
                Categorías
              </Link>
            </div>
          </div>
          <div className={classes.bg}>
            <div className={classes.contentWrap}>
              <GridContainer
                style={{
                  padding: "0 50px",
                }}
              >
                <GridItem xs={12}>
                  <div className={classes.jumboHeading}>{category.name}</div>
                  <p>{category.description}</p>
                </GridItem>
              </GridContainer>
            </div>
          </div>

          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer>
                {grants.length > 0 ? (
                  grants.map((value) => {
                    return (
                      <GridItem key={value.id} xs={12} sm={6} md={4} lg={3}>
                        <div className={classes.boxImage}>
                          <div className={classes.mediaImage}>
                            <Link onClick={() => handleViewGrant(value.id)}>
                              <img
                                className={classes.imgFluid}
                                src={
                                  value.image !== null
                                    ? value.image +
                                      "?" +
                                      moment().format("DDMMYYYYhhmmss")
                                    : NoImage
                                }
                              />
                            </Link>
                          </div>
                          <Link
                            className={classes.bodyLink}
                            onClick={() => handleViewGrant(value.id)}
                          >
                            <div className={classes.bodyContent}>
                              <h4>{truncateText(value.name, 50)}</h4>
                              <p>
                                <span className={classes.bold}>Cantidad: </span>
                                {truncateText(value.amount, 40)}
                                <br></br>
                                <span className={classes.bold}>
                                  Fecha Inicio:{" "}
                                </span>
                                {Moment(value.begin_date).format("DD/MM/YYYY")}
                                <span className={classes.bold}>
                                  <br></br>Fecha Fin:{" "}
                                </span>
                                {Moment(value.end_date).format("DD/MM/YYYY")}
                              </p>
                              <Link
                                onClick={() => handleViewGrant(value.id)}
                                className={classes.button}
                              >
                                <span>Consultar </span>
                                <ArrowRight
                                  style={{
                                    fontSize: "10px",
                                  }}
                                />
                              </Link>
                            </div>
                          </Link>
                        </div>
                      </GridItem>
                    );
                  })
                ) : (
                  <GridItem xs={12}>
                    <div>
                      Actualmente no hemos encontradon ninguna convocatoria que
                      cumpla estas características
                    </div>
                  </GridItem>
                )}
              </GridContainer>
            </div>
          </div>
          {/* <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Ayudas</h4>
                  <p className={classes.cardCategoryWhite}>
                    Ayudas para ofertar
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8} lg={10}>
                      <CustomInput
                        formControlProps={{
                          className: classes.inputSearch,
                        }}
                        inputProps={{
                          placeholder: "Buscar Ayuda",
                          inputProps: {
                            "aria-label": "Buscar",
                          },
                          //onChange={}
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={2}></GridItem>
                  </GridContainer>
                </CardBody>
                <CardBody>
                  <div className={classes.tableWrapper}>
                    <table className={classes.muiTable}>
                      <TableHead>
                        <TableRow>
                          <tableCell></tableCell>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Cantidad</TableCell>
                          <TableCell>Fecha de inicio</TableCell>
                          <TableCell>Fecha de fin</TableCell>
                          <TableCell>Acciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {grants.map((value) => {
                          return (
                            <TableRow key={value.id}>
                              <TableCell>
                                <img
                                  className={classes.imagePreview}
                                  src={
                                    value.image !== null
                                      ? value.image +
                                        "?" +
                                        moment().format("DDMMYYYYhhmmss")
                                      : NoImage
                                  }
                                />
                              </TableCell>
                              <TableCell>{value.name}</TableCell>
                              <TableCell>{value.amount} €</TableCell>
                              <TableCell>
                                {Moment(value.begin_date).format(
                                  "DD/MM/YY HH:mm:ss"
                                )}
                              </TableCell>
                              <TableCell>
                                {Moment(value.end_date).format(
                                  "DD/MM/YY HH:mm:ss"
                                )}
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() => handleViewGrant(value.id)}
                                >
                                  Ver Ayuda
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer> */}
        </div>
      )}
    </div>
  );
}
