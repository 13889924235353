import React, { useState } from "react";
import { login } from "login";
import { rep_login } from "data/repository";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardLogo: {
    maxWidth: "100%",
  },
  redColor: {
    backgroundColor: "#ba0c2f",
    "&:hover, &:focus": {
      backgroundColor: "#be9826",
    },
  },
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const useStyles = makeStyles(styles);

async function loginUser(credentials) {
  return rep_login(credentials);
}

export default function LoginForm() {
  const classes = useStyles();
  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  const history = useHistory();
  const [tc, setTC] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      email,
      password,
    });

    if (typeof token === "string" && token.includes("Error")) {
      if (!tc) {
        setTC(true);
        setTimeout(function () {
          setTC(false);
        }, 6000);
      }
    } else {
      login(token);
      history.push("/admin");
      history.go(0);
    }
  };

  const handleGoRemenber = () => {
    history.push("/remember");
    history.go(0);
  };

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message="El usuario o clave introducidos son incorrectos. Si el error persiste, por favor, póngase en contacto con clubcamara@camaragranada.org."
            open={tc}
            closeNotification={() => setTC(false)}
            close
          />
          <Card>
            <CardHeader
              color="primary"
              style={{
                background: "linear-gradient(60deg, #ba0c2f, #9f102c)",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Iniciar sesión</h4>
              <p className={classes.cardCategoryWhite}>
                Por favor, introduce usuario y clave
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Usuario"
                    id="user"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setUserName(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Clave"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setPassword(e.target.value),
                      type: "password",
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" className={classes.redColor}>
                Entrar
              </Button>
              <Button onClick={() => handleGoRemenber()}>
                He olvidado mi contraseña
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
}
