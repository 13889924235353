import React, { useEffect, useState } from "react";
import {
  rep_getCategory,
  rep_imageCategory,
  config,
  rep_authHeaders,
} from "data/repository";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardImage from "components/Card/CardImage.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";

import image from "assets/img/no_image.jpg";
import { useParams } from "react-router-dom";
import moment from "moment";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  inputImage: {
    display: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Category() {
  let { id } = useParams();
  const classes = useStyles();
  const [category, setCategory] = useState({
    id: id,
    name: "",
    image: image,
    order: 0,
  });
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newImage, setImage] = useState(null);
  const [salving, setSalving] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      setEdit(true);
      getCategoryData();
    } else {
      setLoading(false);
    }
  }, []);

  const getCategoryData = async () => {
    const categoryData = await rep_getCategory(id);

    setCategory(categoryData);
    setLoading(false);
  };

  const saveImage = async (categoryId) => {
    const formData = new FormData();
    formData.append("file", newImage);
    formData.append("id", categoryId);

    rep_imageCategory(formData);
  };

  const handleClickSaveCategory = async () => {
    if (salving) {
      return;
    }

    setSalving(true);

    if (edit) {
      axios
        .put(config.API_URL + "admin/ccg_category/update", category, {
          headers: rep_authHeaders(),
        })
        .then((response) => {
          saveImage(response.data.id), setSalving(false);
        });
    } else {
      axios
        .post(config.API_URL + "admin/ccg_category/store", category, {
          headers: rep_authHeaders(),
        })
        .then((response) => {
          saveImage(response.data.id), setSalving(false);
        });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);

      let image = URL.createObjectURL(e.target.files[0]);
      setCategory({
        id: category.id,
        name: category.name,
        image: image,
        order: category.order,
      });
    }
  };

  if (!loading) {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Crear/Editar Categoría
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Crea/Edita una categoría
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Nombre"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: "" + category.name,
                        onChange: (e) =>
                          setCategory({
                            id: category.id,
                            name: e.target.value,
                            image: category.image,
                            order: category.order,
                          }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CardImage profile>
                      <input
                        accept="image/*"
                        className={classes.inputImage}
                        id="icon-button-file"
                        type="file"
                        onChange={handleImageChange}
                      />
                      <label htmlFor="icon-button-file">
                        <img
                          src={
                            category.image
                              ? category.image.includes("blob") ||
                                category.image.includes("data")
                                ? category.image
                                : category.image +
                                  "?" +
                                  moment().format("DDMMYYYYhhmmss")
                              : image
                          }
                          alt="Selecciona una imagen"
                        />
                      </label>
                    </CardImage>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Orden"
                      id="first-surname"
                      formControlProps={{
                        disabled: true,
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: "" + category.order,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={handleClickSaveCategory}
                  color="primary"
                  disabled={salving}
                >
                  {salving ? "Guardando..." : "Guardar Categoría"}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return <div>Cargando...</div>;
  }
}

Category.propTypes = {
  categoryId: PropTypes.object,
};
