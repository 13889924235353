/*eslint-disable*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { rep_authHeaders, config } from "data/repository";

//Icons
import Map from "@material-ui/icons/LocationOnOutlined";
import Phone from "@material-ui/icons/PhoneRounded";
import Email from "@material-ui/icons/EmailOutlined";
import Instagram from "@material-ui/icons/Instagram";
import Facebook from "@material-ui/icons/Facebook";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Logo from "assets/img/LogoClubCamara.png";
import Logo2 from "assets/img/public-logo.png";

import { isLogged } from "login";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();

  const [loadingData, setLoadingData] = useState(true);
  const [categories, setCategories] = useState([]);
  const [activeGrants, setActiveGrants] = useState({});
  const history = useHistory();


  useEffect(() => {
    if (loadingData) {
      getCategoriesData();
    }
  }, []);

  const getCategoriesData = () => {
    axios
      .get(config.API_URL + "admin/ccg_category/all", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setCategories(response.data), getAllGrantsData();
      });
  };

  const getAllGrantsData = () => {
    axios
      .get(config.API_URL + "admin/ccg_grant/all-active", {
        headers: rep_authHeaders(),
      })
      .then((response) => {
        setActiveGrants(response.data), setLoadingData(false);
      });
  };

  const handleGoCategory = (id) => {
    history.push("/admin/grants/" + id);
    history.go(0);
  };

  return (
    <footer>
      
      <div className={classes.container2}>
        <GridContainer>
          <GridItem sm={12} md={4}>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoIcon}>
                <Map className={classes.icons} />
              </div>
              <div className={classes.infoText}>
                <h4 className={classes.title}>Nuestras Instalaciones</h4>
                <a href="https://www.google.com/maps/place/C%C3%A1mara+de+Comercio+de+Granada/@37.1947554,-3.6165679,17z/data=!3m1!4b1!4m5!3m4!1s0xd71fcfc703cca09:0x63aa74b98be897b!8m2!3d37.1948057!4d-3.6144217" target="_blank" className={classes.a2}>C/ Luis Amador, 26<br></br>18014 Granada</a>
              </div>
            </div>
          </GridItem>
          <GridItem sm={12} md={4}>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoIcon}>
                <Phone className={classes.icons} />
              </div>
              <div className={classes.infoText}>
                <h4 className={classes.title}>Teléfono</h4>
                <a href="tel:+34958536152" className={classes.a2}>+34 958 536 152 (Ext. 1101, 1102, 1106, 1107)</a><br></br>
                <a href="tel:+34689152254" className={classes.a2}>+34 689 152 254</a><br></br>
                <a href="tel:+34646576573" className={classes.a2}>+34 646 576 573</a><br></br>
              </div>
            </div>
          </GridItem>
          <GridItem sm={12} md={4}>
            <div className={classes.rsIconInfo}>
              <div className={classes.infoIcon}>
                <Email className={classes.icons} />
              </div>
              <div className={classes.infoText}>
                <h4 className={classes.title}>Email</h4>
                <a href="mailto:clubcamara@camaragranada.org" className={classes.a2Little}>clubcamara@camaragranada.org</a>
              </div>
            </div>
          </GridItem>
          </GridContainer>
      </div>
      <div className={classes.footer}>
        <GridContainer>
          <GridItem style={{zIndex: 1,}} xs={12} md={4}>
            <div className={classes.logo}>
              <img src={Logo2} alt="Club Cámara Granada"></img>
            </div>
          </GridItem>
          <GridItem xs={12} md={4} style={{width: "100%"}}>
            <div className={classes.centerCategories}>
              {isLogged() ? (
              <h4 className={classes.title}>Categorias:</h4>  
              ) : ("")}
              <GridContainer>
                {categories.map((value) => {
                  return (
                    <GridItem key={value.id} xs={12} lg={6}>
                      <div className={classes.padding}>
                        <Link
                          className={classes.feature_box_7}
                          // color="primary"
                          onClick={() => handleGoCategory(value.id)}
                        >
                          <div className={classes.content}><span style={{fontWeight: "bold", fontSize: "20px"}}></span>{value.name}</div>
                        </Link>
                      </div>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </div>
          </GridItem>
          <GridItem xs={12} md={4} style={{width: "100%"}}>
            <div className={classes.center}>
              <h4 className={classes.title}>Síguenos en redes sociales:</h4>
            </div>
            <div className={classes.center}>
              <a href="https://www.instagram.com/clubcamaragranada/" target="_blank"><Instagram className={classes.socialIcons} /></a>
              <a href="https://www.facebook.com/ClubCamaraGranada" target="_blank"><Facebook className={classes.socialIcons} /></a>
              <a href="https://es.linkedin.com/in/red-club-c%C3%A1mara-grx?trk=public_profile_browsemap_profile-result-card_result-card_full-click" target="_blank"><LinkedIn className={classes.socialIcons} /></a>
            </div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <div className={classes.container}>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#legal" className={classes.block}>
                      Aviso Legal
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#privacidad" className={classes.block}>
                      Política de Privacidad
                    </a>
                  </ListItem>
                </List>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <p className={classes.right}>
              <span className={classes.company}>
                &copy; {1900 + new Date().getYear()}{" "}
                <a
                  href="https://camaragranada.org"
                  target="_blank"
                  className={classes.a}
                >
                  Cámara de Comercio de Granada
                </a>
              </span>
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}
